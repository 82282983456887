.header-form {
  display: -webkit-flex;
  -webkit-align-items: flex-start;
  display: flex;
  align-items: flex-start;
  background-color: var(--naval);
  border-radius: 28px 28px 0 0;
  padding: 1rem;
  position: relative;
  padding: 0.5rem 1rem 2.25rem;

  .ant-btn.ant-btn-circle {
    padding: 5px 6.5px;

    svg {
      fill: var(--alto);
    }
  }

  .ant-btn,
  h2 {
    -webkit-align-self: center;
    align-self: center;
  }

  h2 {
    margin-left: 1rem;
  }

  &.transparent {
    background-color: transparent;
    padding: 0;
    margin-bottom: 1rem;
  }
}

.next-step {
  margin-top: -10px;
  margin-bottom: 1rem;
}

.caracter-cont {
  font-size: 12px;
  color: var(--alto);
  line-height: 1;
  position: relative;
  right: -85%;
  top: -5rem;
  font-weight: 400;
  opacity: 0.8;
}

// MENSAGENS DE ERRO
.has-error:not(.custom) .ant-form-explain,
.has-error:not(.custom) .ant-form-split {
  position: absolute;
  bottom: -1.25rem;
}
.has-error .ant-form-explain,
.has-error .ant-form-split,
.has-error .ant-select-arrow {
  color: var(--pendency);
}

.ant-list-empty-text,
.ant-transfer-list-body-not-found {
  color: var(--alto);

  svg {
    fill: var(--alto);
  }
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: var(--ebony);
}

.ant-form {
  // RESETS
  .ant-input-affix-wrapper {
    .ant-input-suffix {
      z-index: 0;
      right: 0;
    }

    &:hover .ant-input:not(.ant-input-disabled) {
      border-color: var(--gigas);
    }
  }

  .ant-empty-description {
    color: var(--alto);
  }

  .ant-row.ant-form-item {
    .ant-form-item-label {
      text-align: left;
    }
    &.item-inline {
      margin: 0;

      .ant-col {
        display: inline-block;
      }

      .ant-form-item-label label {
        font-size: 14px;
      }

      .ant-form-item-control-wrapper {
        float: right;
      }
    }
  }

  .section {
    background-color: var(--naval);
    padding: 1.5rem;
    border-radius: 0.75rem;
    margin-bottom: 0.75rem;

    .section-title {
      padding-left: 10px;
      font-weight: 500;
    }

    h4 {
      font-size: 1.0625rem;
    }
  }

  .description {
    line-height: 1.5;

    textarea {
      margin-bottom: 0.5rem;
    }

    .title {
      font-weight: 700;
      color: var(--alto);
      font-size: 0.8rem;
    }

    .text {
      color: var(--alto);
      font-size: 0.8rem;
    }
  }

  // ITEM
  .ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
  }

  .ant-form-item-required::before {
    color: var(--pendency);
  }

  // LABEL
  label {
    color: var(--alto);
    &:after {
      display: none;
    }
  }

  /* Cor de fundo do autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px var(--ebony) inset;
  }

  /* Cor do texto do autocomplete */
  input:-webkit-autofill {
    -webkit-text-fill-color: var(--alto) !important;
  }

  // INPUT
  // .ant-input-number {
  //   border: none;
  //   width: 100%;
  //   background: none;
  //   .ant-input-number-input {
  //     padding: 12px;
  //     height: 36px;
  //     background-color: var(--ebony);
  //     border: none;
  //     color: var(--alto);
  //   }
  // }

  // .ant-input-number-disabled {
  //   .ant-input-number-input {
  //     background-color: var(--martinique);
  //     border: dashed 1px;
  //     color: var(--disabled-color);
  //   }
  // }

  .ant-input {
    padding: 12px;
    height: 36px;
    background-color: var(--ebony);
    border: none;
    color: var(--alto);

    .ant-input-group-addon {
      background: transparent;
      border: none;
      right: 18px;
      position: absolute;

      .icon-sesi {
        .search {
          font-size: 1rem;
        }
      }
    }

    &:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(40, 42, 66, 0.2);
      box-shadow: 0 0 0 2px rgba(40, 42, 66, 0.2);
    }

    &[disabled] {
      background: transparent;
      border: dashed 1px;
      color: var(--disabled-color);
    }

    &.has-error {
      background-color: red !important;
    }
  }

  // INPUT SEARCH
  .ant-input-search .ant-input,
  .ant-transfer-list-search {
    background-color: transparent;
    border: none;
    padding: 4px 0 8px;
    border-radius: 0;
    border-bottom: 1px solid var(--alto);
    color: var(--alto);

    &::placeholder {
      color: var(--alto);
    }

    &:focus {
      box-shadow: none;
    }
  }

  .ant-input-search {
    .ant-input-group-addon {
      background: transparent;
      border: none;
      right: 6px;
      position: absolute;
      top: 10px;
    }

    .anticon-search {
      color: var(--alto);
    }
  }

  // TEXT AREA
  textarea.ant-input {
    min-height: 120px;
    border-radius: 4px;
    background: var(--ebony);
    border: none;
    color: var(--alto);
  }

  // SELECT
  .ant-select {
    color: var(--melrose);

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: var(--alto);
    }

    .ant-select-selection {
      background-color: var(--ebony);
      border: 2px solid var(--marguerite);
    }

    .ant-select-selection--single {
      height: 40px;
      border-radius: 4px;

      .ant-select-selection__rendered {
        color: var(--alto);
        height: 100%;
      }
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }

    .ant-select-selection__clear {
      background: transparent;
      color: var(--marguerite);
      right: 30px;
    }

    &.ant-select-disabled {
      border: 1px dashed var(--disabled-color);
      .ant-select-selection {
        background: transparent;
        .ant-select-selection__rendered {
          color: var(--disabled-color);
        }
      }
    }
  }

  // TRANSFER
  .ant-transfer {
    color: var(--alto);

    .ant-transfer-list-header {
      position: relative;
      background: var(--martinique);
      padding: 0 0 0 0;
      border: none;
      display: flex;

      .ant-transfer-list-header-selected :nth-child(1) {
        order: 1;
        display: none;
      }

      .ant-checkbox-wrapper,
      .ant-transfer-list-header-selected span {
        color: var(--alto);
      }

      .ant-transfer-list-header-selected .ant-transfer-list-header-title {
        display: inline-block;
        position: sticky;
        padding: 0px 0px 8px 0px;
        border: none;
      }
    }

    .ant-transfer-list {
      border: none;
      padding-top: 0;
      background-color: var(--ebony);
      width: calc(50% - 40px);
      height: 238px;
    }

    .ant-transfer-list-content-item {
      padding: 0 12px;
    }

    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
      background: transparent;
    }

    .ant-transfer-list-content {
      max-height: 144px;

      > div {
        height: 24px !important;

        &:hover {
          background: none;
        }
      }
    }

    .ant-transfer-operation {
      margin: 0 1.625rem;

      .ant-btn-icon-only.ant-btn-sm {
        width: 1.75rem;
        height: 1.75rem;
      }

      .ant-btn-primary {
        background-color: var(--melrose);
        border: none;
        color: var(--ebony);

        &[disabled] {
          opacity: 0.3;
        }
      }

      button {
        padding: 0 4px;

        i {
          font-size: 16px;
          margin: 4px 0 0 0;

          &:first-child {
            display: none;
          }
        }
      }
    }

    .ant-transfer-list-search-action .anticon {
      color: var(--alto);
    }

    .ant-transfer-list-body-with-search {
      padding-right: 4px;
    }

    .ant-transfer-list-body {
      ul {
        max-height: 132px;
        line-height: 32px;
        @include scrollbar-y(var(--melrose), transparent);

        li {
          span {
            color: var(--alto);
          }
        }
      }
    }
  }

  .ant-tree .ant-tree-icon-hide,
  .ant-form .ant-transfer .ant-transfer-list-body ul,
  .ant-tree li .ant-tree-node-content-wrapper,
  .ant-tree-child-tree .ant-tree-child-tree-open {
    line-height: inherit !important;
  }

  .ant-tree-child-tree > li:first-child {
    padding-top: 0px;
  }

  // CHECKBOX
  .ant-checkbox {
    .ant-checkbox-inner {
      border: 1px solid var(--alto);
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--alto);
  }

  // SWITCH
  &.finish-step .ant-form-item-label {
    width: calc(100% - 56px);
    margin-bottom: 0.35rem;
  }

  .ant-switch-checked {
    background-color: var(--marguerite);
  }

  .ant-switch-loading-icon,
  .ant-switch::after {
    background-color: var(--alto);
  }

  .ant-switch-inner {
    color: var(--melroseSelected);
  }

  // CALENDÁRIO
  .ant-calendar-picker {
    width: 100%;

    .ant-calendar-range-picker-separator,
    .ant-calendar-picker-icon {
      color: var(--alto);
    }

    .ant-calendar-picker-input.ant-input.ant-input-disabled {
      background: transparent;
      border: dashed 1px;
      color: var(--disabled-color);

      .ant-calendar-range-picker-separator,
      .ant-calendar-picker-icon {
        color: var(--disabled-color);
      }
    }
  }

  // UPLOAD
  .ant-upload {
    &.ant-upload-select {
      display: block;
    }
  }

  .ant-upload-list-picture
    .ant-upload-list-item
    .ant-upload-list-item-info
    > span {
    padding-left: 48px;
    padding-right: 12px;
  }

  .ant-upload-list {
    color: var(--alto);

    .ant-upload-list-item {
      padding: 4px 12px;
      border-radius: 4px;
      height: auto;
      font-size: 1rem;
      background-color: var(--ebony);
      margin-top: 12px;

      .anticon-close {
        top: 50%;
        right: 0.75rem;
        transform: translateY(-50%);
        color: var(--alto);
        font-size: 12px;
        font-weight: bold;
      }

      &:hover {
        background-color: var(--ebony);
        color: var(--melroseSelected);
        .ant-upload-list-item-info {
          background-color: transparent;
        }
      }
    }

    .ant-upload-list-item-name {
      padding-left: 0;
    }

    a:hover,
    .anticon-delete {
      color: var(--alto);
    }

    // .ant-upload-list-item-info .anticon-loading,
    .ant-upload-list-item-info .anticon-paper-clip {
      display: none;
    }
  }

  span.erro {
    color: var(--pendency);
  }
}

.ant-upload-list-item-custom {
  color: var(--alto);
  padding: 0 12px;
  border-radius: 4px;
  height: auto;
  font-size: 1rem;
  background-color: var(--ebony);
  margin-top: 12px;
  a:hover {
    color: var(--alto);
  }
  i {
    margin-top: 12px;
    float: right;
    cursor: pointer;
    :hover {
      fill: var(--pendency);
    }
  }
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--melrose);
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--melrose);
}

.search,
.ant-select-arrow {
  color: var(--alto);
  font-size: 1rem;
}

// .dropdown-setores {
//   width: 755px !important;
// }

// .dropdown-cargos {
//   width: 366px !important;
// }

// .dropdown-trabalhadores {
//   width: 366px !important;
// }

// .dropdown-maquinas {
//   width: 755px !important;
// }

// .dropdown-forma-fisica {
//   width: 366px !important;
// }

// .dropdown-fatores-risco {
//   width: 755px !important;
// }

// .dropdown-plano-acao {
//   width: 755px !important;
// }

.ant-upload-list-item {
  background-color: var(--ebony);
  color: var(--alto);
  border-radius: 4px;
  height: 32px;
  padding-top: 4px;

  .anticon {
    padding-top: 4px;
    color: var(--alto);
  }
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: var(--ebony) !important;
  border-radius: 4px;
}

.ant-select-clear {
  background: transparent;
  top: 66%;
  right: 7px;
  color: var(--alto);
  &:hover {
    color: var(--alto) !important;
  }
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: var(--alto);
}

.ant-form-item-label > label .ant-form-item-tooltip {
  color: var(--alto);
}
