.ant-layout {
  background-image: var(--degrade-fundo);
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    height: 100vh;
  }

  .ant-layout-content {
    background-image: url("../imagens/bg-sesi.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
    margin-left: 56px;
    padding: 24px 30px;
    @media (max-width: 767.98px) {
      margin-left: 0;
      padding: 10px;
      margin-top: 64px;
      background-position: -20vw -24vh !important;
    }
  }

  .max-container {
    max-width: 1440px;
    height: 100vh;
    margin: 0 auto;
  }

  // TABLET
  @include media-breakpoint-down(md) {
    //@include scrollbar-y(var(--melrose), transparent);

    .max-container > div > .ant-row > .ant-col,
    .steps-content > div > .ant-row > .ant-col {
      margin-bottom: 2rem;
    }
  }

  // MOBILE
  @include media-breakpoint-down(sm) {
    @include scrollbar-y(var(--melrose), transparent);
  }

  @media (max-width: 767.98px) {
    .responsivo-hidden {
      display: none !important;
    }
    #responsivo {
      display: flex;
      flex-direction: column;
    }
    .max-container {
      height: auto;
    }
    .assistant-wrapper.card .comet-bg .content {
      margin: 1rem 2rem 0 5rem;
    }
    .assistant img {
      height: 110px !important;
    }
    .assistant-wrapper.card.responsivo {
      padding: 2rem 0;
    }
  }
}
// @media only screen and (min-device-width: 768px) and (max-width: 1366px) {
//   .ant-layout-content {
//     transform: scale(0.9);
//     transform-origin: top center;
//   }
//   .sider {
//     transform: scale(0.9);
//     transform-origin: top right;
//     margin-left: -6px;
//     height: 111%;
//   }
//   .ant-layout {
//     .max-container {
//       margin: -2px -64px;
//     }
//   }
// }

.d-flex {
  display: flex;
   &.align-baseline {
    align-items: baseline;
  }
  &.align-center {
    align-items: center;
  }
}